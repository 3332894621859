.addressForm-main {
  width: 100%;
}
.addressForm-main .address .interval {
  padding-left: 0 !important;
  padding-right: 10px !important;
}
.addressForm-main .address .interval:last-child {
  padding-right: 0 !important;
}
.addressForm-main .form_wrap {
  margin-bottom: 0 !important;
}
.addressForm-main .bg_red {
  background: red;
}
.addressForm-main .bg_red input {
  background: red !important;
  border-radius: 0;
}
