.sectionLook-padding {
  padding: 0 20px;
}
.sectionLook-padding .custom-row-section .el-row + .el-row {
  border-top: 2px dashed #58a3f7;
}
.sectionLook-padding .custom-row-section .el-form-item {
  margin-bottom: 0px !important;
}
.sectionLook-padding .custom-row-section .el-row {
  margin: 10px 0;
}
