
.ProjectMapIndex-layout {
	height: 100%;
	position: relative;
	#mapbox {
		width: 100%;
		height: 100%;
		/deep/ .BMap_bubble_content {
			margin-top: 15px !important;
		}

		/deep/ .BMap_cpyCtrl {
			display: none;
		}

		/* 隐藏文字信息 */
		/deep/ .anchorBL {
			display: none;
		}
	}
	.s-result {
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 999;
		width: 400px;
	}
}
.geolocationIcon {
	width: 32px;
	height: 32px;
	cursor: pointer;
	background-image: url(http://api.map.baidu.com/images/geolocation-control/mobile/default-40x40.png);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
	bottom: 9px;
	right: 31px;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0px 0px 10px #777;
}
