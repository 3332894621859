.project-info-main-look /deep/ .el-card__header {
  color: #999;
}
.project-info-main-look /deep/ .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
.project-info-main-look /deep/ .el-form-item__label {
  color: #999;
}
.project-info-main-look .text-overflow {
  color: #202020;
}
.project-info-main-look .text-color {
  color: #60adf9;
  cursor: pointer;
}
.project-info-main-look .auditArea {
  position: absolute;
  right: 10px;
  top: 65px;
  height: 30px;
  width: 200px;
  background-color: white;
}
.project-info-main-look .audBtn1 {
  position: absolute;
  right: 75px;
  top: 0px;
}
.project-info-main-look .audBtn2 {
  position: absolute;
  right: 5px;
  top: 0px;
}
.project-info-main-look .entrust-box {
  padding: 10px;
  line-height: 30px;
  background-color: #eee;
  margin-bottom: 20px;
  font-size: 14px !important;
}
.project-info-main-look .entrust-box .title {
  margin-bottom: 15px;
}
.btn {
  padding: 0 50px !important;
}
.word-break {
  word-break: break-all !important;
}
.border-top {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 3px dotted #eee;
}
