
.addressForm-main {
    width: 100%;
    .address {
        .interval {
            padding-left: 0 !important;
            padding-right: 10px !important;
        }

        .interval:last-child {
            padding-right: 0 !important;
        }
    }
    .form_wrap {
        margin-bottom: 0 !important;
    }
    .bg_red {
        background: red;
        input {
            background: red !important;
            border-radius: 0;
        }
    }
}
