
.project-info-main-look {
	/deep/ .el-card__header {
		color: #999;
	}

	/deep/ .el-form-item--small.el-form-item {
		margin-bottom: 5px;
	}

	/deep/ .el-form-item__label {
		color: #999;
	}

	.text-overflow {
		color: #202020;
	}

	.text-color {
		color: #60adf9;
		cursor: pointer;
	}
	.auditArea {
		position: absolute;
		right: 10px;
		top: 65px;
		height: 30px;
		width: 200px;
		background-color: white;
	}
	.audBtn1 {
		position: absolute;
		right: 75px;
		top: 0px;
	}
	.audBtn2 {
		position: absolute;
		right: 5px;
		top: 0px;
	}
	.entrust-box {
		padding: 10px;
		line-height: 30px;
		background-color: #eee;
		margin-bottom: 20px;
		font-size: 14px !important;
		.title {
			margin-bottom: 15px;
		}
	}
}
.btn {
	padding: 0 50px !important;
}
.word-break {
	word-break: break-all !important;
}
.border-top {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 3px dotted #eee;
}
