
.sectionLook-padding {
	padding: 0 20px;
	.custom-row-section {
		.el-row + .el-row {
			border-top: 2px dashed #58a3f7;
		}
		.el-form-item {
			margin-bottom: 0px !important;
		}
		.el-row {
			margin: 10px 0;
		}
	}
}
